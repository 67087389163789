.page_name_landing-page {
  .info-section {
    &_name_hero {
      .form {
        &__label {
          @apply text-left;
        }

        .fa-circle-info,
        &__dialog__toggle {
          @apply hidden;
        }
      }
    }

    &:not(.info-section_name_hero--has-background) {
      @apply py-8;

      &__footer {
        .button {
          @apply mx-auto;
        }
      }
    }
  }
}
